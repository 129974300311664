import React, {useEffect} from 'react'
import {Layout} from '../../components/layout'
import {Section, Label, Subsection, MiddleSectionPadding} from '../../components/section';
import {passLanguage, useLanguage} from '../../components/language-context'
import {
  BoldText,
  CTAButton,
  OptionImage,
  Row,
  RowCol,
  Row3Col1,
  Row3Col2,
  RowGraphicWrapper,
  SectionSubheading,
  TextParagraph, RowColHalf, ListElement
} from '../../components/platform-common-components'
import {goToRequestForm} from '../../components/request-form';
import {JumboImage} from '../../components/solution-customized-components'
import improveHeaderImage from '../../images/platform/alerts/improve-header@3x.png'
import alertPhonesImage from '../../images/platform/alerts/alert-phones@2x.png'
import {smoothScrollTo} from "../../components/offering-common-components";

const ImprovePageBase = useLanguage(({location, lang, msg, scrollTo}) => {
  useEffect(() => {
    if (!scrollTo) return;
    smoothScrollTo(scrollTo);
  });

  return (
    <Layout location={location}>
      <JumboImage src={improveHeaderImage}/>
      <Section title={msg('platform-alerts-main-title')} lang={lang}>
        <SectionSubheading>{msg('platform-alerts-main-desc')}</SectionSubheading>
      </Section>
      <Subsection
        padding={MiddleSectionPadding}
        title={msg('platform-alerts-section-1-title')}
        lang={lang}
        showLine={true}
        lineWidth={0}
      >
        <Row>
          <TextParagraph>{msg('platform-alerts-section-1-text')}</TextParagraph>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={alertPhonesImage}/>
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col2>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-1-bullet-1-heading')}</BoldText> {msg('platform-alerts-section-1-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-1-bullet-2-heading')}</BoldText> {msg('platform-alerts-section-1-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-1-bullet-3-heading')}</BoldText> {msg('platform-alerts-section-1-bullet-3-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-1-bullet-4-heading')}</BoldText> {msg('platform-alerts-section-1-bullet-4-text')}
            </TextParagraph>
          </Row3Col2>
        </Row>
      </Subsection>
      <Subsection
        padding={MiddleSectionPadding} title={msg('platform-alerts-section-2-title')}
        lang={lang} lineWidth={0}>
        <Row>
          <TextParagraph>{msg('platform-alerts-section-2-text-1')}</TextParagraph>
          <TextParagraph>{msg('platform-alerts-section-2-text-2')}</TextParagraph>
        </Row>
        <Row align={'flex-start'}>
          <RowColHalf>
            <Label>{msg('platform-alerts-section-2-list-1-label')}</Label>
            <ul>
              <ListElement>{msg('platform-alerts-section-2-list-1-bullet-1')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-1-bullet-2')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-1-bullet-3')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-1-bullet-4')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-1-bullet-5')}</ListElement>
            </ul>
          </RowColHalf>
          <RowColHalf>
            <Label>{msg('platform-alerts-section-2-list-2-label')}</Label>
            <ul>
              <ListElement>{msg('platform-alerts-section-2-list-2-bullet-1')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-2-bullet-2')}</ListElement>
              <ListElement>{msg('platform-alerts-section-2-list-2-bullet-3')}</ListElement>
            </ul>
          </RowColHalf>
        </Row>
      </Subsection>
      <Subsection
        title={msg('platform-alerts-section-3-title')} lang={lang}
        headerTitleLabel={msg('platform-alerts-section-3-title-label')} lineWidth={0}>
        <Row>
          <RowCol>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-3-bullet-1-heading')}</BoldText> {msg('platform-alerts-section-3-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-3-bullet-2-heading')}</BoldText> {msg('platform-alerts-section-3-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-3-bullet-3-heading')}</BoldText> {msg('platform-alerts-section-3-bullet-3-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-3-bullet-4-heading')}</BoldText> {msg('platform-alerts-section-3-bullet-4-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-alerts-section-3-bullet-5-heading')}</BoldText> {msg('platform-alerts-section-3-bullet-5-text')}
            </TextParagraph>
          </RowCol>
        </Row>
        <Row>
          <TextParagraph>
            <CTAButton onClick={(event) => {goToRequestForm(event, lang, location.pathname)}}>{msg('platform-alerts-section-3-button-text')}</CTAButton>
          </TextParagraph>
        </Row>
      </Subsection>
    </Layout>
  )
})

const ImprovePage = passLanguage(ImprovePageBase)

export default ImprovePage